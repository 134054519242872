import { useEffect } from 'react';
import { getQueryParam } from 'src/utils/url';
import { FROM_URL } from 'src/constants/queryParams';
import { getReferrerPage, saveInStoreFromUrl } from 'src/utils/redirect';

const useFromUrlSave = () => {
  useEffect(() => {
    const fromUrl = getQueryParam(FROM_URL); // || getReferrerPage();

    if (fromUrl) {
      saveInStoreFromUrl(fromUrl);
    }
  }, []);
};

export default useFromUrlSave;
